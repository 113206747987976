export default Object.freeze({
    "labels": {
        "diagnosis_of_sdgs": "Diagnosis of SDGs",
        "survey_header_text": "Just by answering simple multiple-choice questions that can be answered in about 5 minutes, we will score your efforts for each SDGs.",
        "view_survey_result": "View diagnostic results",
        "sdgs_diagnosis_results": "SDGs Diagnosis Results",
        "output_in_pdf": "Output in PDF",
        "total_score": "Total score",
        "point": "point",
        "industry_average": "Industry average",
        "size_average": "Average for companies of similar size",
        "consideration_for_environment": "Consideration for the environment",
        "consideration_for_society": "Consideration for society",
        "consideration_for_governance": "Consideration for governance",
        "finance_productivity": "Finance/Productivity",
        "reporting_of_non_financial_information": "Management and reporting of non-financial information",
        "priority": "Priority",
        "out_of_20_points": " points out of 20",
        "diagnose_again": "Diagnose Again",
        "no_question_found": "No data found.",
        "field_required_error_message": "This question is required.",
        "form_submitted_successfully": "Survey form submitted successfully",
        "login": "Login",
        "login_successfully": "Login successfully",
        "create_t4e_account": "Create New Account",
        "email": "Email Id",
        "password":"Password",
        "privacy_policy": "Privacy Policy",
        "terms_of_service": "Terms of Service",
        "operating_company": "Operating company",
        "inquiry": "Inquiry",
        "logout": "Logout"
    },
    "placeholders": {
    },
    "buttons": {
        "SDGsサマリ": "SDGs Summary",
        "ESGサマリ": "ESG Summary"
    },
    "errors": {
        "email_required" : "Email is requried",
        "email_valid" : "E-mail must be valid",
        "password_required" : "Password is required"
    },
    "messages": {

    }
});

