export default Object.freeze({
    "labels": {
        "diagnosis_of_sdgs": "SDGs診断",
        "survey_header_text": "5分ほどで回答可能な簡単な選択型の質問にお答えいただくだけで、SDGsの各目標における取組状況をスコアリングします。",
        "view_survey_result": "診断結果をみる",
        "sdgs_diagnosis_results": "SDGs診断結果",
        "output_in_pdf": "PDFで 出力",
        "total_score": "トータルスコア",
        "point": "点",
        "industry_average": "同業種平均",
        "size_average": "同規模企業平均",
        "consideration_for_environment": "環境（Environment）への配慮",
        "consideration_for_society": "社会（Social）への配慮",
        "consideration_for_governance": "ガバナンス（Governance)への配慮",
        "finance_productivity": "財務・生産性",
        "reporting_of_non_financial_information": "非財務情報の管理・レポーティングの整備",
        "priority": "優先課題",
        "priority_2": "優先課題2",
        "out_of_20_points": "点/20点中",
        "diagnose_again": "もう一回診断する",
        "no_question_found": "アンケートデータをロードできませんでした",
        "field_required_error_message": "この質問は必須です。",
        "form_submitted_successfully": "回答を提出できました。",
        "login": "ログイン",
        "login_successfully": "ログインしました",
        "create_t4e_account": "アカウントを新規作成",
        "email": "メールアドレス",
        "password":"パスワード",
        "privacy_policy": "プライバシーポリシー",
        "terms_of_service": "利用規約",
        "operating_company": "運営会社",
        "inquiry": "お問い合わせ",
        "logout": "ログアウト"
    },
    "placeholders": {
    },
    "buttons": {
        "SDGsサマリ": "SDGsサマリ",
        "ESGサマリ": "ESGサマリ"
    },
    "errors": {
        "email_required" : "メールアドレスを入力してください。",
        "email_valid" : "メールアドレス形式を確認してください。",
        "password_required" : "パスワードを入力してください。"
    },
    "messages": {

    }
});

