import Vue from 'vue';
import Router from 'vue-router';

const Home = import('@/views/Home');
const SurveyDiagnosisResult = import('@/views/SurveyDiagnosisResult');

Vue.use(Router);

const routes = [
{
    path: '*',
    redirect: '/'
},    
{
    path: '/',
    name: 'HomePage',
    component: () => Home,
    meta: {
        requiresAuth: false
    }
},
{
    path: "/surveyresult",
    name: "surveydiagnosisresult",
    component: () => SurveyDiagnosisResult,
    meta: {
      requiresAuth: false,
    },
  },
];

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior () {
        return { x: 0, y: 0 }
    }
})