import axios from 'axios';

export default function setup() {
    // Add a request interceptor
    axios.interceptors.request.use(request => {
        let getToken = localStorage.getItem('token');
        let suslabId = localStorage.getItem('suslab_id');
        request.params = {token: getToken,suslab_id:suslabId}
        return request;
    })
    // Add a response interceptor
    axios.interceptors.response.use(function(response) {
        return response;
    }, function(error) {
        return Promise.reject(error);
    });
}
