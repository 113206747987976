import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import constant from './constant';
import router from "./router";
import store from "./store";
import interceptorsSetup from "./interceptors";
import i18n from '@/plugins/i18n';
import Vuelidate from 'vuelidate';
import VueToast from 'vue-toast-notification';
Vue.config.productionTip = false
Vue.use(Vuelidate);
Vue.use(VueToast, {
  // One of the options
  position: 'top',
  duration: 3000
});
router.beforeEach((to, from, next) => {
  i18n.locale = localStorage.getItem('language') || 'jp'

  if (process.env.VUE_APP_BUILD_ENVIRONMENT == "GC") {
    window.document.title = 'SX Survey Lite';
  } else {
    window.document.title = 'T4E Lite';
  }

  return next()
})
export const eventBus = new Vue();
// call vue axios interceptors
interceptorsSetup();
new Vue({
  router,
  i18n,
  vuetify,
  constant,
  store,
  render: h => h(App)
}).$mount('#app')
