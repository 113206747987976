import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        token: '',
        suslab_id: ""
    },
    mutations: {
        setAuthToken(state, data) {
            localStorage.setItem('token', data)
            state.token = data;
        },
        setSuslabId(state, data) {
            localStorage.setItem('suslab_id', data)
            state.suslab_id = data;
        },
        setReportId(state,data){
            localStorage.setItem('report_id', data)
            state.report_id = data;
        }
    },
    actions: {
        saveAuthToken({ commit }, data) {
            commit('setAuthToken', data);
        },
        saveSuslabId({ commit }, data) {
            commit('setSuslabId', data);
        },
        saveReportId({ commit }, data) {
            commit('setReportId', data);
        }
    },
    getters: {
        getToken: state => {
            return state.token;
        }
    }
})