<template>
  <v-app id="app" class="page-wrapper">
    <router-view />
  </v-app>
</template>
<script>
export default {
  name: "App",
};
 import("./assets/scss/_main.scss");
 import("vue-toast-notification/dist/theme-sugar.css");
</script>